import React, { useEffect } from 'react';
import {Routes, Route} from 'react-router-dom';
// import {usePropertiesContext} from './context/PropertiesContext';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap'
import '../src/assets/css/style.css';
import "aos/dist/aos.css"
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import 'react-tabs/style/react-tabs.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Pages
import Error404 from './pages/Error404';
import Home from './pages/Home'
import FAQs from './pages/FAQs'
import ContactUs from './pages/ContactUs';
import IndividualListing from './pages/IndividualListing';
import Aos from 'aos';
import Why800Homes from './pages/Why800Homes';
import Leadership from './pages/Leadership';
import WhyDubai from './pages/WhyDubai';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import Careers from './pages/Careers';
import Blogs from './pages/Blogs';
import BuyProperty from './pages/BuyProperty';
import Blog from './pages/Blog';
import SellProperty from './pages/SellProperty';
import ThankYou from './pages/ThankYou';
import PropertyDetails from './pages/PropertyDetails';
import Listing1 from './pages/listing/Listing1';
import Listing2 from './pages/listing/Listing2';
import Listing3 from './pages/listing/Listing3';
import Listing4 from './pages/listing/Listing4';
import Listing5 from './pages/listing/Listing5';
import Listing6 from './pages/listing/Listing6';
import Listing7 from './pages/listing/Listing7';
import Listing8 from './pages/listing/Listing8';
import Listing9 from './pages/listing/Listing9';
import Listing10 from './pages/listing/Listing10';
import Listing11 from './pages/listing/Listing11';
import Listing12 from './pages/listing/Listing12';
import Listing13 from './pages/listing/Listing13';
import Listing14 from './pages/listing/Listing14';
import Listing15 from './pages/listing/Listing15';
import Listing16 from './pages/listing/Listing16';
import Listing17 from './pages/listing/Listing17';
import Listing18 from './pages/listing/Listing18';
import ProfileFaraazSiddiqui from './pages/ProfileFaraazSiddiqui';
import ProfileAanilMannwani from './pages/ProfileAanilMannwani';
import Profile800Homes from './pages/Profile800Homes';

const App = () => {
  useEffect(()=>{
    Aos.init({duration: 2000});
  })

  // const {properties} = usePropertiesContext();
  return (
    <>
      <Routes>
        <Route path="*" element={<Error404/>} />
        <Route path="/thank-you" element={<ThankYou/>} />
        <Route path="/faraaz-siddiqui" element={<ProfileFaraazSiddiqui/>} />
        <Route path="/aanil-mannwani" element={<ProfileAanilMannwani/>} />
        <Route path="/800-homes-real-estate-brokerage" element={<Profile800Homes/>} />
        <Route path="/" element={<Home/>} />
        <Route path="/why-800-homes" element={<Why800Homes/>} />
        <Route path="/why-dubai" element={<WhyDubai/>} />
        <Route path="/leadership" element={<Leadership/>} />
        <Route path="/sell-property" element={<SellProperty/>} />
        <Route path="/properties-for-sale" element={<BuyProperty/>} />
        <Route path="/property/:slug" element={<PropertyDetails/>} />
        {/* <Route path="/apartment-for-sale-sharjah-aljada-naseej-district-il-teatro-residences-2" element={<Listing1/>} /> */}
        <Route path="/townhouse-for-sale-sharjah-tilal-city-masaar-saro" element={<Listing2/>} />
        <Route path="/townhouse-for-sale-dubai-damac-hills-2-pacifica-casablanca-boutique-villas" element={<Listing3/>} />
        <Route path="/villa-for-sale-dubai-dubai-south-dubai-world-central-emaar-south-greenviews-2" element={<Listing4/>} />
        <Route path="/apartment-for-sale-dubai-dubai-residence-complex-ag-7even" element={<Listing5/>} />
        <Route path="/apartment-for-sale-sharjah-aljada-nesba" element={<Listing6/>} />
        <Route path="/apartment-for-sale-dubai-al-furjan-primero-residences" element={<Listing7/>} />
        <Route path="/apartment-for-sale-dubai-al-furjan-pearlz-by-danube" element={<Listing8/>} />
        <Route path="/villa-for-sale-dubai-dubai-south-dubai-world-central-south-bay-south-bay-4" element={<Listing9/>} />
        <Route path="/apartment-for-sale-dubai-dubai-science-park-vincitore-aqua-dimore" element={<Listing10/>} />
        <Route path="/apartment-for-sale-dubai-dubai-south-dubai-world-central-south-living" element={<Listing11/>} />
        <Route path="/apartment-for-sale-dubai-dubai-science-park-vincitore-aqua-flora" element={<Listing12/>} />
        <Route path="/villa-for-sale-dubai-dubai-south-dubai-world-central-south-bay-south-bay-5" element={<Listing13/>} />
        <Route path="/villa-for-sale-dubai-dubai-south-dubai-world-central-south-bay-south-bay-6" element={<Listing14/>} />
        <Route path="/apartment-for-sale-dubai-jumeirah-village-triangle-terhab-hotels-towers-tower-3" element={<Listing15/>} />
        <Route path="/apartment-for-sale-dubai-dubai-marina-six-senses-residences" element={<Listing16/>} />
        <Route path="/apartment-for-sale-sharjah-aljada-nest-nest-6" element={<Listing17/>} />
        <Route path="/townhouse-for-sale-dubai-damac-hills-2-camelia" element={<Listing18/>} />
        <Route path="/individual-listing" element={<IndividualListing/>} />
        <Route path="/careers" element={<Careers/>} />
        <Route path="/faqs" element={<FAQs/>} />
        <Route path="/blogs" element={<Blogs/>} />
        <Route path="/blog/:slug" element={<Blog/>} />
        <Route path="/contact-us" element={<ContactUs/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions/>} />
      </Routes>
      <ToastContainer 
          position="bottom-left"
          autoClose={5000}
        />

    </>
  );
}

export default App;
