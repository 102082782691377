import React, { useState, useEffect, useRef } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import AllPerperties from './AllPerperties';
import Logo from '../../assets/images/800home/Logo.webp';
import { Spinner } from 'react-bootstrap';
import { usePropertiesContext } from '../../context/PropertiesContext';

const ITEMS_PER_PAGE = 15;

const MainPageOfListing = () => {
    const { properties, loading } = usePropertiesContext();
    const [allProperties, setAllProperties] = useState([]);
    const [apartmentsData, setApartmentsData] = useState([]);
    const [townhousesData, setTownhousesData] = useState([]);
    const [villasData, setVillasData] = useState([]);
    const [penthousesData, setPenthousesData] = useState([]);
    const [allPage, setAllPage] = useState(0);
    const [apartmentsPage, setApartmentsPage] = useState(0);
    const [townhousesPage, setTownhousesPage] = useState(0);
    const [villasPage, setVillasPage] = useState(0);
    const [penthousesPage, setPenthousesPage] = useState(0);

    const propertiesTopRef = useRef(null);

    useEffect(() => {
        if (properties && properties.length > 0) {
            setAllProperties(properties);
            setApartmentsData(properties.filter((property) => property.type.id === 1));
            setTownhousesData(properties.filter((property) => property.type.id === 9));
            setVillasData(properties.filter((property) => property.type.id === 10));
            setPenthousesData(properties.filter((property) => property.type.id === 8)); // assuming penthouses id is 3
        }
    }, [properties]);

    const sliceData = (data, page) => data.slice(page * ITEMS_PER_PAGE, (page + 1) * ITEMS_PER_PAGE);

    const handleScrollToTop = () => {
        if (propertiesTopRef.current) {
            propertiesTopRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const renderPagination = (data, currentPage, setPage) => {
        const totalPages = Math.ceil(data.length / ITEMS_PER_PAGE);
        if (totalPages <= 1) return null; // Hide pagination if items are fewer than the page count

        return (
            <div className="pagination-controls">
                <button
                    disabled={currentPage === 0}
                    onClick={() => {
                        setPage((prev) => prev - 1);
                        handleScrollToTop();
                    }}
                >
                    Previous
                </button>
                {[...Array(totalPages).keys()].map((page) => (
                    <button
                        key={page}
                        className={currentPage === page ? 'active px-3' : 'px-3'}
                        onClick={() => {
                            setPage(page);
                            handleScrollToTop();
                        }}
                    >
                        {page + 1}
                    </button>
                ))}
                <button
                    disabled={currentPage + 1 >= totalPages}
                    onClick={() => {
                        setPage((prev) => prev + 1);
                        handleScrollToTop();
                    }}
                >
                    Next
                </button>
            </div>
        );
    };

    return (
        <div id="website-responsive" className="py-5" ref={propertiesTopRef}>
            <Tabs>
                <TabList className="text-center fs-ss-18 fw-300 mx-auto" data-aos="fade-up">
                    <Tab>All Properties</Tab>
                    <Tab>Apartments</Tab>
                    <Tab>Penthouses</Tab>
                    <Tab>Townhouses</Tab>
                    <Tab>Villas</Tab>
                </TabList>

                <section className="container-fluid px-md-0 pt-md-2">
                    {loading ? (
                        <div className="text-center fs-ss-18 lh-1-7em fw-300 col-md-8 mx-auto text-white py-5" data-aos="fade-up">
                            <img src={Logo} alt="Logo" width={70} className="loader" />
                            <div className="mt-2">
                                <Spinner animation="grow" size="sm" variant="light" />
                            </div>
                        </div>
                    ) : (
                        <>
                            <TabPanel>
                                <div className="row">
                                    <AllPerperties data={sliceData(allProperties, allPage)} />
                                    {renderPagination(allProperties, allPage, setAllPage)}
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="row">
                                    <AllPerperties data={sliceData(apartmentsData, apartmentsPage)} />
                                    {renderPagination(apartmentsData, apartmentsPage, setApartmentsPage)}
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="row">
                                    <AllPerperties data={sliceData(penthousesData, penthousesPage)} />
                                    {renderPagination(penthousesData, penthousesPage, setPenthousesPage)}
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="row">
                                    <AllPerperties data={sliceData(townhousesData, townhousesPage)} />
                                    {renderPagination(townhousesData, townhousesPage, setTownhousesPage)}
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="row">
                                    <AllPerperties data={sliceData(villasData, villasPage)} />
                                    {renderPagination(villasData, villasPage, setVillasPage)}
                                </div>
                            </TabPanel>
                        </>
                    )}
                </section>
            </Tabs>
        </div>
    );
};

export default MainPageOfListing;
