import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Logo from '../assets/images/800home/Logo.webp';
import { Spinner } from "react-bootstrap";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import DetailsOfListing from "../components/listing/individual/DetailsOfListing";
import InquiryOfListing from "../components/listing/individual/InquiryOfListing";
import ShareSocialMediaIcons from "../components/listing/individual/ShareSocialMediaIcons";
import FeaturesAndAmenities from "../components/listing/individual/FeaturesAndAmenities";
import ListingDetailsSection from "../components/listing/individual/ListingDetailsSection";
import { usePropertiesContext } from "../context/PropertiesContext";

const PropertyDetails = () => {
    
    const { properties, loading } = usePropertiesContext();
    const navigate = useNavigate();

    // Extract property based on URL
    const propertyUrl = window.location.pathname.split('/')[2];
    const property = properties?.find((p) => p.url === propertyUrl);

    useEffect(() => {
        // Redirect to "/properties-for-sale" if property is not found after loading
        if (!loading && !property) {
            navigate("/properties-for-sale", { replace: true });
        } else {
            window.scrollTo(0, 0);
        }
    }, [property, loading, navigate]);

    if (loading) {
        return (
            <div className="text-center fs-ss-18 lh-1-7em fw-300 col-md-8 mx-auto text-white py-5" data-aos="fade-up">
                <img src={Logo} alt="Logo" width={70} className="loader" />
                <div className="mt-2">
                    <Spinner animation="grow" size="sm" variant="light" />
                </div>
            </div>
        );
    }

    if (!property) {
        return (
            <div className="text-center fs-ss-18 lh-1-7em fw-300 col-md-8 mx-auto text-white py-5" data-aos="fade-up">
                <img src={Logo} alt="Logo" width={70} className="loader" />
                <div className="mt-2">No property found</div>
            </div>
        );
    }

    return (
        <>
            <Header />
            <section id="website-responsive" className="container-fluid py-5 border-top border-1 border-white">
                <div className="row justify-content-evenly">
                    <div className="col-11 col-md-7 px-0">
                        <ImageGallery
                            className="transition"
                            items={property.images.map((image) => ({ original: image.file }))}
                        />
                        <DetailsOfListing
                            Title=""
                            Title2={property.name}
                            Price={property.price?.value?.toLocaleString()}
                            Location={property.location.city}
                            Category={property.type.name}
                            Beds={property.bedrooms}
                            Baths={property.bathrooms}
                            Area={
                                <>
                                    <span>{Math.round(property.size)} sqft</span>
                                    <span className="ms-2"> / {Math.round(property.size * 0.092903)} sqm</span>
                                </>
                            }
                            FeaturesAndAmenities={
                                property.amenities.map((amenity) => (
                                    <FeaturesAndAmenities key={amenity.name} Title={amenity.name} />
                                ))
                            }
                            Description={property.description}
                            ListingDetailsSection={
                                <>
                                    <ListingDetailsSection Title1="Property Type" Title2={property.type.name} />
                                    <ListingDetailsSection
                                        Title1="Property Size"
                                        Title2={
                                            <>
                                                <span>{Math.round(property.size)} sqft</span>
                                                <span className="ms-2"> / {Math.round(property.size * 0.092903)} sqm</span>
                                            </>
                                        }
                                    />
                                    <ListingDetailsSection Title1="Bedrooms" Title2={property.bedrooms} />
                                    <ListingDetailsSection Title1="Bathrooms" Title2={property.bathrooms} />
                                </>
                            }
                        />
                    </div>
                    <div className="col-11 col-md-3">
                        <div className="border-white border-1 border p-4 mb-4 sticky-top">
                            <InquiryOfListing 
                                propertyName={property.name} 
                                propertyUrl={property.url}

                            />
                            <ShareSocialMediaIcons
                                url_Email="https://800homes.eacademe.org/example"
                                url_Whatsapp="https://800homes.eacademe.org/example"
                                url_Facebook="https://800homes.eacademe.org/example"
                                url_Linkedin="https://800homes.eacademe.org/example"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default PropertyDetails;
